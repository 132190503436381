import { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Pagination, Space, message, Flex, Avatar, Input, Select, Typography, Tooltip, Tag } from 'antd';
import dayjs from 'dayjs';
import { deleteTx, fetchTxs } from '../../api/crypto';
import { useDebouncedCallback } from 'use-debounce';
import { capitalizeFirstLetter } from '../../utils/comm';
import { InfoCircleOutlined } from '@ant-design/icons';
import TransactionDetailModalView from '../../components/crypto/transaction_detail_modal_view';

const TransactionsScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({
        fromAddress: '',
        toAddress: '',
        txId: '',
        status: '',
    });
    const [currTx, setCurrTx] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            render: (text, _, index) => {
                return (pageNum - 1) * pageSize + index + 1;
            }
        },
        {
            title: 'TxId',
            dataIndex: 'txId',
            key: 'txId',
            render: (text, _) => {
                if (!text) return '-';
                return <div style={{ maxWidth: 200 }}>
                    <Typography.Text copyable style={{ fontSize: 12 }}>
                        {text}
                    </Typography.Text>
                </div>;
            }
        },
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (text, _) => {
                if (!text) return '-';
                return <Tooltip title={text}>
                    <Avatar size={32} style={{ background: '#fde3cf', color: '#f56a00' }}>{capitalizeFirstLetter(text)[0]}</Avatar>
                </Tooltip>
            }
        },
        {
            title: 'From',
            dataIndex: 'fromAddress',
            key: 'fromAddress',
            render: (text, _) => {
                if (!text) return '-';
                return <div style={{ maxWidth: 200 }}>
                    <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{text}</Typography.Text>
                </div>;
            }
        },
        {
            title: 'To',
            dataIndex: 'toAddress',
            key: 'toAddress',
            render: (text, _) => {
                if (!text) return '-';
                return <div style={{ maxWidth: 200 }}>
                    <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{text}</Typography.Text>
                </div>;
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                if (!text) return '-';
                return `${text} ${record.symbol}`
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (text === 0) {
                    return <Tag color='processing'>Pending</Tag>
                } else if (text === 1) {
                    return <Tag color='success'>Mined</Tag>
                } else {
                    return <Tooltip title={<div style={{ fontSize: 12, maxWidth: 240 }}>{record.remark}</div>}>
                        <Tag color='error'>{text === 2 ? 'Failed' : 'Dropped'} <InfoCircleOutlined /></Tag>
                    </Tooltip>
                }
            }
        },
        {
            title: 'Wallet ID',
            dataIndex: 'walletId',
            key: 'walletId',
            render: (text, _) => {
                if (!text) return '-';
                return <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{text}</Typography.Text>;
            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { showDetail(record) }}>Detail</Button>
                        {
                            record.status === 0 &&
                            <>
                                <Button type='link'>Cancel</Button>
                                <Button type='link'>Speed up</Button>
                            </>
                        }
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this wallet?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getTxs(pageNum, pageSize, searchParams);
    }, [])

    const getTxs = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search
        }
        fetchTxs(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getTxs(page, size, searchParams);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteTx(record.id).then(res => {
            if (res) {
                message.success('deleted!');
                getTxs(pageNum, pageSize, searchParams);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    const showDetail = (record) => {
        setCurrTx(record);
        setModalOpen(true);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getTxs(1, pageSize, newParams);
        },
        300
    )

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <div />
                    <Space>
                        <Input
                            placeholder="from..."
                            allowClear
                            onChange={evt => onSearchChange('fromAddress', evt.target.value)}
                        />
                        <Input
                            placeholder="to..."
                            allowClear
                            onChange={evt => onSearchChange('toAddress', evt.target.value)}
                        />
                        <Input
                            placeholder="txid..."
                            allowClear
                            onChange={evt => onSearchChange('txId', evt.target.value)}
                        />
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'Pending', value: 0 },
                                { key: 2, label: 'Mined', value: 1 },
                                { key: 3, label: 'Failed', value: 2 },
                                { key: 4, label: 'Dropped', value: 3 },
                            ]}
                            placeholder='Status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <TransactionDetailModalView
                open={modalOpen}
                tx={currTx}
                onClose={() => {
                    setModalOpen(false);
                }}
            />
        </div>
    )
}


export default TransactionsScreen;