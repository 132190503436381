import { Modal } from "antd"
import { useEffect } from "react";
import { fetchTxDetail } from "../../api/crypto";

const TransactionDetailModalView = ({tx, open, onClose}) => {
    useEffect(() => {
        if(tx) {
            fetchTxDetail({id: tx.id, session: tx.session}).then(res => {})
        }
    }, [tx])
    return (
        <Modal title='Transaction Detail' open={open} onCancel={onClose} footer={null} width={600}>

        </Modal>
    )
}

export default TransactionDetailModalView;