import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from 'react';
import { BucketType, firebaseConfig } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { message, Modal, Select, Space, Typography, Spin, Button } from 'antd';
import { getImageBucket } from "../../utils/base_url";
import { fetchAllTags } from '../../api/community';
import { conversionUtcDate } from '../../utils/comm';
import { useDebouncedCallback } from 'use-debounce';


const RichTextEditor = ({ contentField, defaultHeight, defaultContent, onUpdate, needEditTags = true }) => {
    // outdent indent
    const editorRef = useRef(null);
    const [topics, setTopics] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [topicId, setTopicId] = useState(null);

    const getTopicTags = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'name': keyword,
                'level': 1,
                'isTopicTag': false,
                'status': 0,
            }
            setSearchLoading(true);
            fetchAllTags(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: item.name,
                        value: item.id,
                    }));
                    setTopics(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const onSelectChange = (value) => {
        setTopicId(value);
    }

    const confirmInsertTag = () => {
        let tagName = topics.find(item => item.value === topicId).label;
        let editSelection = editorRef.current.selection;
        if(editSelection) {
            let range = editSelection.getRng();
            let newNode = editorRef.current.getDoc().createElement('span');
            newNode.innerHTML = `<a class="ql-hashtag" href="" id="${topicId}" style="text-decoration:none;" spellcheck="false"><span contenteditable="false">#${tagName}</span></a >`;
            range.insertNode(newNode);
        }
        setTopicId(null);
        setModalOpen(false);

        onUpdate(contentField, editorRef.current.getContent(), tagName);
    }

    return (
        <div>
            <Editor
                id={contentField}
                value={defaultContent}
                init={{
                    height: defaultHeight,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                        'contenttagbtn | undo redo | formatselect |fontselect fontsizeselect | bold italic backcolor forecolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist | removeformat',
                    file_browser_callback_types: 'image',
                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = function () {
                            var file = this.files[0];
                            let storage = firebaseConfig(BucketType.image);
                            const storageRef = ref(storage, `/post/${encodeURIComponent(file.name.replace(/[ !@#$%^&*(),?":{}|<>]/g, '-'))}`);
                            const uploadTask = uploadBytesResumable(storageRef, file);
                            message.loading('Image uploading...');
                            uploadTask.on(
                                "state_changed",
                                (snapshot) => { },
                                (err) => {
                                    message.destroy();
                                    message.error(String(err));
                                },
                                () => {
                                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                        let updated = url;
                                        if (updated.includes('/o/')) {
                                            updated = 'https://' + getImageBucket() + '/' + decodeURIComponent(updated.split('?')[0]).split('/o/')[1];
                                        }
                                        message.destroy();
                                        cb(updated, { title: file.name });
                                    }).catch((err) => {
                                        message.destroy();
                                        message.error(String(err));
                                    });
                                }
                            );
                        };
                        input.click();
                    },
                    setup: (editor) => { 
                        if(!needEditTags) return;
                        // 自定义工具栏按钮
                        editor.ui.registry.addButton('contenttagbtn', {
                            text: 'Insert #',
                            onAction: () => {
                                setModalOpen(true);
                            }
                        });
                    }
                }}
                onInit={(evt, editor) => {
                    editorRef.current = editor;
                }}
                onEditorChange={(data, _) => onUpdate(contentField, data)}
            />
            <Modal
                closable
                open={modalOpen}
                centered
                title="Insert Content Tag"
                zIndex={111}
                onCancel={() => setModalOpen(false)}
                footer={[
                    <Button key={'tagbtn'} type='primary' disabled={!topicId} onClick={confirmInsertTag}>Confirm Insert</Button>
                ]}>
                <div style={{ padding: '20px 0' }}>
                    <Select
                        style={{ width: '100%' }}
                        size='large'
                        placeholder='you can input keywords to search topic'
                        value={topicId}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={(value) => getTopicTags(value)}
                        notFoundContent={searchLoading ? <Spin /> : null}
                        options={topics}
                        optionRender={(option) => {
                            return (
                                <Space>
                                    <div style={{ flex: 1 }}>
                                        <b style={{ wordBreak: 'break-word' }}>{option.data.label}</b>
                                        <div>
                                            <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                {option.data.createdAt}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Space>
                            )
                        }}
                        onChange={onSelectChange}
                        allowClear
                        showSearch
                    />
                </div>
            </Modal>
        </div>
    )
}

export default RichTextEditor;